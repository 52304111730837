.Hero {
  margin: 30px 0;
}

.Heading {
  font-size: 48px;
  font-weight: 700;
  text-transform: capitalize;
}

.Subheading {
  font-size: 22px;
  font-weight: 400;
  color: var(--grey-color);
  margin: 20px 0;
}

.Img {
  width: 100%;
}

.Form {
  width: 100%;
  margin-top: 40px;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  border-radius: 10px;
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 20px 5px rgba(230, 230, 230, 1);
  -moz-box-shadow: 0px 0px 20px 5px rgba(230, 230, 230, 1);
  box-shadow: 0px 0px 20px 5px rgba(230, 230, 230, 1);
}

.Label {
  color: var(--primary-color);
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  width: 100%;
}

.Input {
  border: none;
  margin-top: 5px;
  outline: none;
  width: 100%;
}

.Btn {
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  color: white;
  background-color: var(--primary-color);
  transition-duration: 0.3s;
}

.Btn:hover {
  background-color: var(--primary-color-dark);
}

@media only screen and (max-width: 991px) {
  .Hero {
    margin: 50px 0;
  }
  .Container {
    width: 90%;
  }
  .Heading {
    font-size: 36px;
    text-align: center;
  }
  .Subheading {
    font-size: 20px;
    font-weight: 400;
    text-align: center;
  }
  .CTADiv {
    text-align: center;
  }
  .Label {
    width: 100%;
  }
  .Btn {
    width: 100%;
    padding: 15px 0;
  }
  .Img {
    margin-top: 50px;
  }
}
