.Text h1 {
  margin-bottom: 30px;
}

.Text h2 {
  margin: 20px 0;
}

.Text p {
  margin-bottom: 15px;
}
