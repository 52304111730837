.Section {
  padding: 50px 0;
}

.TagList {
  margin: 30px 0;
}

.Tag {
  width: fit-content;
  border: none;
  border-radius: 100px;
  padding: 5px 30px;
  font-size: 14px;
  margin: 0 5px;
  background-color: rgb(240, 240, 240);
}
