span.required {
    color:red
}

.input-3 {
    border: none;
    border-bottom: 2px solid black;
    padding: 5px 5px;
    transition-duration: 0.3s;
    margin-top:5px;
    width:100%
}

.input-3:focus-visible {
    outline: none;
    border: none;
    border-bottom: 2px solid #0e9ff2;
    transition-duration: 0.3s;
}