.Section {
  margin: 30px 0;
}

.tourImage {
  width: 100%;
  height: 500px;
  background-color: rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.Img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.tourContent {
  padding: 30px 0;
  display: flex;
  justify-content: space-between;
}

.leftSideContent {
  width: 65%;
}

.rightSideContent {
  width: 35%;
  display: flex;
  justify-content: end;
}

.IconsSection {
  font-size: 13px;
  padding: 20px 0;
  margin-top: 30px;
  border-top: 2px solid rgb(240, 240, 240);
  border-bottom: 2px solid rgb(240, 240, 240);
}

.IconBox {
  width: fit-content;
  text-align: center;
}

.IconBox p {
  font-size: 15px;
}

.Icon {
  font-size: 30px !important;
  margin-bottom: 7.5px;
  color: #0e9ff2;
}

.breadcrumb {
  font-size: 14px;
  margin-bottom: 20px;
}

.Title {
  margin-bottom: 30px;
}

.Description p {
  margin-bottom: 20px;
}

.Description p:last-child {
  margin-bottom: 0;
}

.tourSectionHeading {
  margin: 40px 0 30px 0 !important;
  font-weight: 500;
  font-size: 28px;
}

.CheckIcon {
  margin-right: 15px;
  color: #85cd79;
  font-size: 25px;
}

.CancelIcon {
  margin-right: 15px;
  color: #cf142b;
  font-size: 25px;
}

.Form {
  width: 90%;
  height: fit-content;
  padding: 30px;
  background-color: white;
  border-radius: 20px;
  -webkit-box-shadow: 0px 0px 20px 5px rgba(230, 230, 230, 1);
  -moz-box-shadow: 0px 0px 20px 5px rgba(230, 230, 230, 1);
  box-shadow: 0px 0px 20px 5px rgba(230, 230, 230, 1);
}

.Age {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  width: fit-content;
  padding: 0;
}

.Age span {
  font-weight: 400;
  color: rgb(150, 150, 150);
  font-size: 13px;
  margin-left: 5px;
}

@media only screen and (max-width: 991px) {
  .Section {
    margin: 0;
  }
  .tourContent {
    display: block;
    position: relative;
  }
  .tourImage {
    border-radius: 0;
    aspect-ratio: 16 / 9;
  }
  .leftSideContent {
    width: 100%;
  }
  .rightSideContent {
    width: 100%;
  }
  .IconsSection {
    padding: 0;
    padding-top: 20px;
  }
  .IconBox {
    width: 50%;
    margin-bottom: 20px;
  }
  .Form {
    width: 90%;
    margin: 0 auto;
    position: fixed;
    top: 90%;
    left: 0;
    right: 0;
  }
}
