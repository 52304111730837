.Label {
  font-size: 13px;
  color: #0e9ff2;
  text-transform: uppercase;
  font-weight: 700;
  width: 100%;
  display: block;
}

.Required {
  color: red;
  margin-right: 5px;
}

.Optional {
  margin-left: 2.5px;
  color: rgb(150, 150, 150);
}
