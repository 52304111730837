.SectionHeading {
  font-size: 36px;
  font-weight: 700;
  text-transform: capitalize;
}

@media screen and (max-width: 991px) {
  .SectionHeading {
    font-size: 30px;
  }
}
