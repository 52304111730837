.Section {
  padding: 75px 0;
  background-color: rgb(240, 240, 240);
}

.Img {
  width: 100%;
}

.IconCard {
  width: 32%;
  text-align: center;
}

.IconCard h3 {
  font-size: 22px;
  margin: 20px 0;
}

.Icon {
  width: 60px;
  height: 60px;
  overflow: hidden;
  margin: 0 auto;
}

.Icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 991px) {
  .Section {
    padding: 100px 0;
  }
  .IconCard {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }
}
