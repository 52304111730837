.CardExterior {
  width: 100%;
  padding: 0 10px;
  margin-bottom: 30px;
}

.Card {
  border-radius: 5px;
  background-color: white;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(196, 196, 196, 1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(196, 196, 196, 1);
  box-shadow: 0px 0px 10px 0px rgba(196, 196, 196, 1);
  overflow: hidden;
  transition-duration: 0.3s;
}

.Card:hover {
  -webkit-box-shadow: 0px 0px 30px 0px rgba(196, 196, 196, 1);
  -moz-box-shadow: 0px 0px 30px 0px rgba(196, 196, 196, 1);
  box-shadow: 0px 0px 20px 5px rgba(196, 196, 196, 1);
  transition-duration: 0.3s;
  cursor: pointer;
}

.Card:hover .Image {
  transform: scale(1.1);
  transition-duration: 0.3s;
}

.ImageDiv {
  width: 100%;
  aspect-ratio: 16 / 9;
  overflow: hidden;
  border-radius: 0 0;
}

.Image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition-duration: 0.2s;
}

.featuredTextDiv {
  width: fit-content;
  padding: 5px 12.5px;
  position: relative;
  bottom: 92.5%;
  left: 7.5%;
  border-radius: 5px;
  background-color: red;
}

.featuredText {
  color: white;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
}

.TourDetail {
  padding: 30px 40px;
}

.Title {
  width: 100%;
  overflow: hidden;
  color: black;
  font-size: 21px;
  font-weight: 500;
  margin-bottom: 10px;
}

.Subtitle {
  font-size: 12px;
  color: grey;
  text-transform: uppercase;
  font-weight: 500;
}

.IconDiv {
  color: gray;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}

.Icon {
  color: #0e9ff2;
  font-size: 25px;
  margin-right: 10px;
}

.Duration {
  font-size: 14px;
  font-weight: 500;
}

.Price {
  display: block;
  font-size: 17px;
  font-weight: 600;
  color: black;
  margin-top: -2px;
}

@media only screen and (max-width: 991px) {
  .CardExterior {
    width: 100%;
    margin-bottom: 20px;
  }
}
