.ServiceCard {
  width: 32%;
  padding: 20px 30px;
  border-radius: 20px;
  text-align: center;
  background-color: white;
  -webkit-box-shadow: 0px 0px 20px 5px rgba(230, 230, 230, 1);
  -moz-box-shadow: 0px 0px 20px 5px rgba(230, 230, 230, 1);
  box-shadow: 0px 0px 20px 5px rgba(230, 230, 230, 1);
}

.IconBox {
  width: 60px;
  height: 60px;
  background-color: var(--primary-color-light);
  border-radius: 100px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Icon {
  color: var(--primary-color);
  font-size: 30px;
}

.ServiceTitle {
  font-size: 20px;
  font-weight: 600;
  margin: 20px 0;
}

.ServiceDescription {
  font-size: 15px;
}

@media screen and (max-width: 991px) {
  .ServiceCard {
    width: 100%;
    margin-bottom: 40px;
  }
  .ServiceCard:nth-of-type(3) {
    margin-bottom: 0px;
  }
}
