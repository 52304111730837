.testimonialCard {
  width: 100%;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(196, 196, 196, 1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(196, 196, 196, 1);
  box-shadow: 0px 0px 10px 0px rgba(196, 196, 196, 1);
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.ImageContainer {
  width: 40%;
  height: 350px;
  background-color: black;
}

.ImageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.Content {
  width: 60%;
  padding: 0px 50px;
}

.Content p {
  font-size: 16px;
  color: rgb(100, 100, 100);
}

.Content .Author {
  color: var(--primary-color);
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 20px;
}

@media only screen and (max-width: 991px) {
  .testimonialCard {
    width: 100%;
    margin-bottom: 20px;
    display: block;
  }
  .ImageContainer {
    width: 100%;
    height: 200px;
  }
  .Content {
    width: 100%;
    padding: 30px 50px;
  }
}
