.Header {
  -webkit-box-shadow: 0px 0 10px 2px rgba(200, 200, 200, 1);
  -moz-box-shadow: 0px 0 10px 2px rgba(200, 200, 200, 1);
  box-shadow: 0px 0 10px 2px rgba(200, 200, 200, 1);
}

.Navigation {
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
}

.NavLogoBox {
  width: 12.5%;
}

.NavLogo {
  width: 100%;
}

.NavLinksBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.NavLink {
  text-transform: uppercase;
  font-size: 13.5px;
  font-weight: 500;
  padding: 10px 10px;
  margin: 0 7px;
  transition-duration: 0.2s;
}

.NavLink:hover {
  color: var(--primary-color);
}

.NavCTABox {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: end;
}

@media only screen and (max-width: 991px) {
  .Navigation {
    display: none;
  }
}
