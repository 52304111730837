.BtnPrimary {
  background-color: var(--primary-color);
  color: white;
  border: 2px solid var(--primary-color);
  width: fit-content;
  border-radius: 3px;
  font-weight: 500;
  font-size: 15px;
  padding: 10px 25px;
  transition-duration: 0.3s;
  margin: 0 0px;
}

.BtnPrimary:hover {
  border: 2px solid var(--primary-color-dark);
  background-color: var(--primary-color-dark);
  transition-duration: 0.3s;
}

.BtnPrimaryOutline {
  background-color: transparent;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
  width: fit-content;
  border-radius: 3px;
  font-weight: 500;
  font-size: 15px;
  padding: 10px 25px;
  transition-duration: 0.3s;
  margin: 0 0px;
}

.BtnPrimaryOutline:hover {
  background-color: var(--primary-color);
  color: white;
}
