.destinationCard {
  width: 24%;
  height: 400px;
  padding: 0;
  margin: 0%;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  transition: 0.3s;
  position: relative;
}

.destinationCard:hover {
  -webkit-box-shadow: 0px 0px 15px 5px rgba(200, 200, 200, 1);
  -moz-box-shadow: 0px 0px 15px 5px rgba(200, 200, 200, 1);
  box-shadow: 0px 0px 15px 5px rgba(200, 200, 200, 1);
}

.destinationImageDiv {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 1), rgb(0, 0, 0));
  overflow: hidden;
  padding: 0%;
}

.destinationImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  opacity: 0.7;
  overflow: hidden;
  transition-duration: 0.1s;
  transition-duration: 0.3s;
}

.destinationCard:hover .destinationImage {
  transition-duration: 0.3s;
  cursor: pointer;
  transform: scale(1.05);
}

.DestinationDetail {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  color: white;
  position: relative;
  bottom: 56.5%;
}

.DestinationDetail h4 {
  font-size: 24px;
  text-align: center;
  font-weight: 700;
}

.DestinationDetail p {
  text-align: center;
  text-transform: capitalize;
  transition-duration: 0.3s;
}

.destinationCard:hover .DestinationDetail p {
  display: block;
  transition-duration: 0.3s;
}

@media only screen and (max-width: 991px) {
  .destinationCard {
    width: 100%;
    height: 225px;
  }
}
