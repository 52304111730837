.Footer {
  padding: 50px 0;
  background-color: rgb(240, 240, 240);
  -webkit-box-shadow: 0px 0 10px 2px rgba(200, 200, 200, 1);
  -moz-box-shadow: 0px 0 10px 2px rgba(200, 200, 200, 1);
  box-shadow: 0px 0 10px 2px rgba(200, 200, 200, 1);
}

.Logo {
  width: 40%;
}

.About {
  margin: 20px 0 30px 0;
}
.SocialLink {
  font-size: 25px;
  margin-right: 20px;
  transition-duration: 0.3s;
}

.Facebook:hover {
  color: #1877f2;
}

.Instagram:hover {
  color: #e1306c;
}

.LinkedIn:hover {
  color: #0a66c2;
}

.Tiktok:hover {
  color: #00f2ea;
}

.ColHeading {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 20px;
  text-align: center;
}

.Link {
  display: block;
  margin-bottom: 10px;
  transition-duration: 0.3s;
  text-align: center;
}

.Link:hover {
  color: #0e9ff2;
}

.Hide {
  display: none;
}

.UpIcon {
  width: 50px;
  height: 50px;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 20px;
  bottom: 20px;
  background-color: var(--primary-color);
  color: white;
  border-radius: 100px;
}

@media only screen and (max-width: 991px) {
  .ColHeading {
    margin-top: 30px;
    text-align: left;
  }
  .Link {
    margin-bottom: 5px;
    text-align: left;
  }
}
