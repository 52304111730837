.Input {
  width: 100%;
  border-radius: 5px;
  border: none;
  background-color: rgb(242, 242, 242);
  padding: 10px 20px;
  font-size: 14px;
  transition-duration: 0.3s;
}

.Input:focus-visible,
.Input:hover {
  outline: none;
  background-color: rgb(230, 230, 230);
}

.Input::placeholder {
  color: rgb(184, 184, 184);
  font-size: 14px;
}
