.Section {
  padding: 30px 0 50px 0;
}

.Title {
  font-size: 32px;
  font-weight: 600;
  text-transform: capitalize;
}

.ImgDiv {
  width: 100%;
  aspect-ratio: 16 / 8;
  overflow: hidden;
  border-radius: 10px;
  overflow: hidden;
  margin: 40px 0;
}

.Img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

br {
  line-height: 2;
}

.RelatedBlogsText {
  margin-bottom: 20px;
}

.Dates {
  width: fit-content;
  height: fit-content;
  padding: 0;
  text-align: end;
}

.Dates p {
  color: rgb(70, 70, 70);
  font-size: 15px;
}

/* Blog Text */

.BlogText p,
.BlogText li {
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: 300;
}

.BlogText strong,
.BlogText strong {
  font-weight: 600;
}

.BlogText h2 {
  font-size: 26px;
  margin: 30px 0;
  font-weight: 600;
}

.BlogText h3 {
  font-size: 23px;
  margin: 30px 0;
  font-weight: 400;
}

/* Author */

.AuthorCard {
  width: fit-content;
  padding: 0;
  height: fit-content;
}

.AuthorImgDiv {
  width: 40px;
  height: 40px;
  background-color: black;
  border-radius: 100px;
  margin-right: 15px;
}

.AuthorName {
  width: fit-content;
  padding: 0;
}

.AuthorAndDateSection {
  align-items: center;
  justify-content: space-between;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  margin: 20px 0;
  padding: 15px 0;
}
