.FAQCard {
  margin-bottom: 15px;
}

.QuestionBox {
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  background-color: var(--light-grey-color);
  padding: 15px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.Question {
  width: 80%;
  color: black;
  font-size: 16px;
}

.Question:hover {
  cursor: pointer;
}

.FAQIcon {
  text-align: end;
  width: fit-content;
  color: black;
  font-weight: 800;
}

.Answer {
  padding: 0 20px;
  font-size: 16px;
}

.Show {
  margin-top: 15px;
  color: rgb(100, 100, 100);
  height: fit-content;
  transition-duration: 0.3s;
}
.Hidden {
  height: 0;
  overflow: hidden;
  transition-duration: 0.3s;
}
