.Navbar {
  display: none;
}

@media only screen and (max-width: 991px) {
  .Navbar {
    display: block;
    padding: 20px 0;
    -webkit-box-shadow: 0px 0 10px 2px rgba(200, 200, 200, 1);
    -moz-box-shadow: 0px 0 10px 2px rgba(200, 200, 200, 1);
    box-shadow: 0px 0 10px 2px rgba(200, 200, 200, 1);
  }
  .Icon {
    font-size: 35px;
    text-align: left;
  }
  .Logo {
    width: 100%;
  }
  .Hidden {
    width: 0;
    position: static;
    display: none;
    transition: 0.3s;
  }
  .Show {
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1000;
    transition-duration: 0.5s;
  }
  .Modal {
    width: 75%;
    height: 100%;
    background-color: white;
    padding: 30px 30px;
    transition-duration: 0.5s;
  }
  .CloseIcon {
    font-size: 30px;
    text-align: end;
  }
  .NavLinks {
    margin-top: 50px;
  }
  .NavLink {
    display: block;
    text-align: center;
    padding: 10px 0;
    transition-duration: 0.3s;
    border-radius: 5px;
    font-size: 17px;
  }
  .NavLink:hover {
    background-color: #0e9ff2;
    color: white;
  }
}
